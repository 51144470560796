import React from "react"
import { Footer } from "../components/footer/Footer"
import { Header } from "../components/header/Header"
import BannMarque from "../components/marque/BanniereMarque"
import MarqueContenu from "../components/marque/marqueContent"
import { graphql } from "gatsby"
import FicheProduitContenu from "../components/fiche-produits/FicheProduit"
import { Helmet } from "react-helmet"

const produit = ({ data , pageContext }) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Produit -{" "}
          {data.allSetemProduct.edges[0]?.node.hit._source.reference ?? ""}
        </title>
        <meta property="og:locale" content="fr_FR" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={`Produit - ${
            data.allSetemProduct.edges[0]?.node.hit._source.reference ?? ""
          }`}
        />

        <meta
          name="description"
          content={`Contient les détails du produit  ${
            data.allSetemProduct.edges[0]?.node.hit._source.reference ?? ""
          }`}
        />
        <meta
          property="og:description"
          content={`Contient les détails du produit  ${
            data.allSetemProduct.edges[0]?.node.hit._source.reference ?? ""
          }`}
        />
        <meta
          property="og:url"
          content={`https://brand.setem.fr/${
            data.allSetemProduct.edges[0]?.node.hit._source.reference ?? ""
          }/`}
        />
        <meta property="og:site_name" content="SETEM" />
      </Helmet>

      <Header />
      <FicheProduitContenu
          data={data}
          //description_marque={pageContext.description_marque}
          //description_produit={pageContext.description_produit}
      />
      <Footer />
    </div>
  )
}

export const query = graphql`
  query ($ref_produit: String) {
    productS3(hit: { name: { eq: $ref_produit } }) {
      hit {
        name
        type
        brand
        description
        children {
          name
          type
          children {
            name
          }
        }
      }
    }
    allSetemProduct(
      filter: { hit: { _source: { reference: { eq: $ref_produit } } } }
    ) {
      edges {
        node {
          key
          hit {
            _id
            _source {
              usine
              reference
              results
              image_url
              code
              brand
            }
          }
        }
      }
    }
  }
`

export default produit
